<template>
  <div class="document-view">
    <div class="document-title">
      {{ $t('views.documents.title') }}
    </div>
    <div
      v-if="hasDocuments"
      class="documents-information"
    >
      <documents-content
        :business-documents="getDocuments"
      />
    </div>
    <form-documents
      v-else
      class="documents-form"
      :new-business="false"
      :is-to-edit="true"
      @submited="createDocuments"
    />
    <div class="document-button">
      <rectoplus-button
        v-if="hasDocuments"
        :text="$t('views.documents.update_documents')"
        class="update-button"
        @click="goToUpdateDocuments"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FormDocuments from '@/components/forms/register/business/FormDocuments.vue';
import DocumentsContent from '@/components/forms/documents/DocumentsContent.vue';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';

export default {
  name: 'Document',
  components: {
    FormDocuments,
    DocumentsContent,
    RectoplusButton,
  },
  computed: {
    ...mapGetters([
      'getBusiness',
      'getDocuments',
    ]),
    hasDocuments() {
      return this.getDocuments !== null;
    },
  },
  mounted() {
    this.fetchBusinessDocuments({ business_id: this.getBusiness.id });
  },
  methods: {
    ...mapActions([
      'fetchBusinessDocuments',
      'createBusinessDocuments',
    ]),
    createDocuments(params) {
      this.createBusinessDocuments(params);
    },
    goToUpdateDocuments() {
      this.$router.push({ name: 'update-documents' });
    },
  },
};
</script>
<style lang="scss" scoped>
  .document-view {
    background: $dashboard_background;
    min-height: 100vh;
    padding: 2em 2em 2em 16em;
    display: flex;
    flex-direction: column;

    .documents-information {
      width: 100%;
      align-items: center;
    }

    .document-title {
      text-align: center;
      font-size: 1.5em;
      margin-bottom: 40px;
      font-weight: bold;
    }

    .documents-form {
      width: 100%;
    }

    .document-button {
      display: flex;
      justify-content: flex-end;
      margin: 20px 0 0 0;

      .update-button {
        display: flex;
        width: 250px;
        text-align: center;
        height: 50px;
        font-weight: bold;
        font-size: 1em;
      }
    }
  }
</style>
