<template>
  <div class="documents-content">
    <div
      v-for="document in documentsArray"
      v-show="document.show"
      :key="document.id"
      class="document-item"
    >
      <span class="document-title"> {{ document.title }} </span>
      <div>
        <a
          target="_blank"
          :class="['document-button', { 'disabled': !documentIsPresent(businessDocuments[document.fileUrl]) }]"
          @click="goToDocumentsPath(businessDocuments[document.fileUrl].url)"
        >
          {{ $t('components.documents_content.show') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'DocumentsContent',
  props: {
    businessDocuments: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      documentsArray: [
        {
          title: this.$t('components.documents_content.social_contract'),
          fileUrl: 'social_contract',
          show: true,
        },
        {
          title: this.$t('components.documents_content.business_license'),
          fileUrl: 'business_license',
          show: true,
        },
        {
          title: this.$t('components.documents_content.balance_calibration_certificate'),
          fileUrl: 'balance_calibration_certificate',
          show: true,
        },
        {
          title: this.$t('components.documents_content.photographic_register'),
          fileUrl: 'photograph_register',
          show: true,
        },
        {
          title: this.$t('components.documents_content.operation_photograph_register'),
          fileUrl: 'operation_photograph_register',
          show: true,
        },
        {
          title: this.$t('components.documents_content.environment_license'),
          fileUrl: 'environment_license',
          show: !this.businessDocuments.has_environment_exemption,
        },
        {
          title: this.$t('components.documents_content.exemption_environmental_license'),
          fileUrl: 'exemption_environmental_license',
          show: this.businessDocuments.has_environment_exemption,
        },
        {
          title: this.$t('components.documents_content.fire_department_license'),
          fileUrl: 'fire_department_license',
          show: true,
        },
      ],
    };
  },

  methods: {
    ...mapActions(['addErrorMessage']),

    goToDocumentsPath(file) {
      if (file) {
        window.open(file);
      } else {
        this.addErrorMessage(this.$t('errors.document_not_send'));
      }
    },

    documentIsPresent(file) {
      return file && file?.url;
    },
  },
};
</script>
<style lang="scss" scoped>
  .documents-content {

    .document-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0 0 20px 0;

      .document-title {
        font-weight: bold;
        font-size: 1em;
      }

      .document-button {
        width: 150px;
        height: 50px;
        background-color: transparent;
        border: 2px $color_primary solid;
        color: $color_primary;
        font-size: 1em;
        font-weight: bold;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        &.disabled {
          color: rgba($color_gray, 0.6);
          border: 2px rgba($color_gray, 0.6) solid;

        }
      }
    }
  }
</style>
